.arrow {
  display: flex;
  padding: var(--spacing-regular) var(--spacing-x-large) var(--spacing-xx-large) var(--spacing-x-large);
}

@media only screen and (min-width: 721px) {
  .arrow {
    padding-top: var(--spacing-xx-large);
    padding-bottom: var(--spacing-xxxx-large);
  }
}
