.illustratedTextGridContainer {
  margin: 0 auto;

  max-width: 330px;
}

.desktopSeparator {
  display: none !important;
}

@media only screen and (min-width: 721px) {
  .illustratedTextGridContainer {
    max-width: 1080px;
  }

  .desktopSeparator {
    display: flex !important;
    flex-direction: column;
  }

  .mobileSeparator {
    display: none !important;
  }
}
