.redesignedInput {
  display: flex;
  align-items: flex-end;

  overflow: hidden;

  min-height: 38px;
  border-radius: 4px;
  border: 1px solid rgb(var(--greyscale-level-5));

  background-color: rgb(var(--greyscale-level-7));
}

.redesignedInput :global(.web_ui__Input__input) {
  flex: 1;
  background: transparent;

  min-height: 38px;
  padding: 0 0 0 calc(var(--spacing-x-small));
}

.redesignedInput :global(.web_ui__Input__content) {
  padding: calc(var(--spacing-regular));
}

.redesignedInput :global(.web_ui__Input__note) {
  text-align: start;
  padding: var(--spacing-small);
}

.redesignedInput :global(.web_ui__Text__warning) {
  color: var(--color-warning);
}

.redesignedInput :global(.web_ui__Input__note) > :global(.web_ui__Text__warning) {
  font-size: 12px;
  line-height: 16px;
}

.redesignedInput :global(.web_ui__Input__input .web_ui__Input__value) {
  padding: 0;
  border: none;
  color: rgb(27, 27, 27);
}

.redesignedInput :global(.web_ui__Input__input .web_ui__Input__value::placeholder) { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(var(--greyscale-level-3));
  opacity: 1; /* Firefox */
}

.redesignedInput :global(.web_ui__Input__input .web_ui__Input__value:-ms-input-placeholder) { /* Internet Explorer 10-11 */
  color: rgb(var(--greyscale-level-3));
}

.redesignedInput :global(.web_ui__Input__input .web_ui__Input__value::-ms-input-placeholder) { /* Microsoft Edge */
  color: rgb(var(--greyscale-level-3));
}

.redesignedInput :global(.web_ui__Input__suffix) {
  /* This makes suffix be visually aligned with text as text is usually slightly closer to the top of container to leave space under for letters like g. */
  bottom: 1px;
}

.redesignedInputError {
  border-color: rgba(var(--warning-default), 1);
}

.redesignedInputPrefix {
  display: flex;
  align-items: center;

  padding-left: 0;
}

.redesignedInputPrefix :global(.web_ui__Input__input) {
  padding-left: 0;
}

.validationOrNote {
  padding-left: var(--spacing-small);
}

.suffix {
  margin: auto 0;
}

.prefix {
  padding-left: var(--spacing-regular);
}

.title {
  padding-bottom: var(--spacing-small);;
}

