.wrapper {
  width: 100%;
  padding: var(--spacing-x-large);
  background-color: rgb(var(--backgrounds-primary));
}

.content {
  margin: 0 auto;
  max-width: 1230px;

  display: flex;
  align-items: center;
  flex-direction: column;
}

.description {
  margin: auto;
  max-width: 330px;
}

.logoList {
  display: grid;

  margin: auto;

  column-gap: var(--spacing-x-large);
  row-gap: var(--spacing-xx-large);

  grid-template-columns: 1fr 1fr;
}

@media only screen and (min-width: 720px) {
  .description {
    margin: auto;
    max-width: 650px;
  }

  .logoList {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
  }
}
