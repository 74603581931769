.wrapper {
  --desktop-calculator-max-width: 536px;

  width: 100%;
  background-color: rgb(var(--primary-dark));
}

.content {
  margin: 0 auto;
  max-width: 1230px;
}

.calculatorAndHeroImageContainer {
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  padding-bottom: var(--spacing-regular);
}

.heroImage {
  display: none;
  position: relative;

  width: 250px;
  height: 237px;

  margin-bottom: var(--spacing-x-large);
}

@media only screen and (min-height: 680px) {
  .heroImage {
    display: inline;
  }
}

/* Same value as --desktop-calculator-max-width */
@media only screen and (min-width: 536px) {
  .calculatorAndHeroImageContainer {
    padding: var(--spacing-xxxx-large) 0;
  }

  .heroImage {
    display: none;
  }
}

@media only screen and (min-width: 1048px) {
  .calculatorAndHeroImageContainer {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }

  .heroImage {
    display: inline;
    width: 480px;
    height: 455px;
  }
}