.wrapper {
  width: 100%;
  padding: var(--spacing-x-large);
}

.content {
  margin: 0 auto;
  max-width: 1230px;

  display: flex;
  align-items: center;
  flex-direction: column;
}

.propositions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 60px;
}

@media only screen and (min-width: 720px) {
  .propositions {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    gap: 60px;
  }
}
