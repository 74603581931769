/* TODO: figure out which Text type to use instead of overriding Web-ui for this specific case. */
.buyLabelButton :global(h2) {
  font-family: 'V-RobotoFlex';
}

/* With Web-ui v34 default expose color was changed meanwhile here it should remain the same which is not called brand accent.
This is because button component does not support accent theme. */
.buyLabelButton :global(.web_ui__Button__expose) {
  color: rgba(var(--brand-accent));
}
