.gridItemContainer {
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (max-width: 720px) {
  .gridItemContainer {
    gap: var(--spacing-x-large);
  }
}

@media only screen and (min-width: 721px) {
  .gridItemContainer > * {
    flex: 1;
    margin: auto;
  }
  
  .text {
    display: flex;
    flex-direction: column;

    max-width: 446px;
  }

  .actionButton {
    display: flex;
  }

  .reverse {
    flex-direction: row-reverse;
  }

  .textExtendedPaddingRight {
    padding-left: var(--spacing-medium);
    padding-right: var(--spacing-xxxx-large);
  }

  .textExtendedPaddingLeft {
    padding-left: var(--spacing-xxxx-large);
    padding-right: var(--spacing-medium);
  }
}

@media only screen and (min-width: 1048px) {
  .textPaddingRight {
    padding-left: 0;
    padding-right: var(--spacing-xxx-large);
  }

  .textPaddingLeft {
    padding-right: 0;
    padding-left: var(--spacing-xxx-large);
  }

  .textExtendedPaddingRight {
    padding-left: var(--spacing-medium);
    padding-right: var(--spacing-xxxxxx-large);
  }

  .textExtendedPaddingLeft {
    padding-left: var(--spacing-xxxxxx-large);
    padding-right: var(--spacing-medium);
  }
}
