.wrapper {
  --desktop-calculator-max-width: 536px;

  padding: var(--spacing-regular);
  background-color: var(--color-deep-teal);
}

.synopsisMobile {
  display: inline;
}

.synopsisMobile .title :global(h1) {
  font-size: 32px;
  line-height: 45px;
  max-width: 280px;
}

.dropdownTitleMobile {
  display: inline;
}

.synopsisDesktop {
  display: none;
}

.dropdownTitleDesktop {
  display: none;
}

.cta {
  display: inline;
}

/* Same value as --desktop-calculator-max-width */
@media only screen and (min-width: 536px) {
  .wrapper {
    padding: var(--spacing-xxx-large);
    background-color: var(--color-white-linen);
    max-width: var(--desktop-calculator-max-width);
  }

  .synopsisMobile {
    display: none;
  }

  .synopsisDesktop {
    display: inline;
  }

  .synopsisDesktop .title :global(h1) {
    font-size: 48px;
    line-height: 60px;
  }

  .dropdownTitleMobile {
    display: none;
  }
  
  .dropdownTitleDesktop {
    display: inline;
  }

  .cta {
    display: flex;
  }
}