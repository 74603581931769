.wrapper {
  width: 100%;
  padding: var(--spacing-x-large);
  background-color: rgb(var(--primary-dark));
}

.content {
  margin: 0 auto;
  max-width: 1230px;

  display: flex;
  align-items: center;
  flex-direction: column-reverse;
}

.titleDesktop {
  display: none;
}

.titleMobile {
  max-width: 330px;
}

.cta {
  height: 100%;
  width: 100%;
  max-width: 330px;
  margin: auto 0;
}

.buyLabelButton {
  display: inline;
}

.map {
  position: relative;

  height: 292px;
  width: 100%;
  max-width: 330px;
}

.map img {
  width: 100%;
  height: 100%;
}

.networkNumbers {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  margin: 0 auto;
  max-width: 330px;
}

@media only screen and (min-width: 720px) {
  .content {
    margin: 0 auto;
    max-width: 1080px;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }

  .titleDesktop {
    display: inline;
  }
  
  .titleMobile {
    display: none;
  }

  .cta {
    max-width: 503px;
  }

  .buyLabelButton {
    display: flex;
  }

  .map {
    height: 502px;
    max-width: 567px;
  }

  .networkNumbers {
    margin: 0;
    max-width: 100%;

    flex-direction: column;
    justify-content: space-between;
  }
}
