.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: var(--spacing-small);
  background-color: rgb(var(--greyscale-level-7));

  padding: var(--spacing-medium);

  height: 55px;
  width: 100%;
  max-width: 154px;
}

@media only screen and (min-width: 720px) {
  .wrapper {
    height: 70px;
  }
}
