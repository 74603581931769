.wrapper {
  width: 100%;
  padding: var(--spacing-x-large);
}

.content {
  margin: 0 auto;
  max-width: 1230px;

  display: flex;
  align-items: center;
  flex-direction: column;
}

.note {
  margin: 0 auto;

  max-width: 330px;
}

@media only screen and (min-width: 721px) {
  .note {
    max-width: 980px;
  }
}
