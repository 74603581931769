.wrapper {
  width: 100%;
  padding: var(--spacing-x-large);
  background-color: rgb(var(--backgrounds-primary));
}

.content {
  margin: 0 auto;
  max-width: 1230px;

  display: flex;
  align-items: center;
  flex-direction: column;
}
