.proposition {
  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 330px;
}

.propositionImage {
  margin: auto;
  max-width: 140px;
  max-height: 100px;
}

.propositionTitle {
  max-width: 280px;
}

/* TODO: figure out which Text type to use instead of overriding Web-ui for this specific case. */
.propositionTitle span {
  font-family: 'V-RobotoFlex';
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 31px;
}

.propositionContent {
  max-width: 330px;
}

@media only screen and (min-width: 720px) {
  .proposition {
    max-width: 382px;
  }

  .propositionTitle {
    max-width: 382px;
  }

  .propositionContent {
    max-width: 264px;
    min-height: 110px;
  }
}