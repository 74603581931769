/* TODO: figure out which Text type to use instead of overriding Web-ui for this specific case. */
.wrapper h1 {
  font-family: 'V-Inter';
}

.postfix h1 {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0px;
}

.valueRow {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.valueWithPostfix {
  width: 80px;
}

@media only screen and (min-width: 721px) {
  .wrapper {
    --number-row-width: 330px;

    width: calc(var(--number-row-width) + var(--spacing-xx-large));
    padding-right: var(--spacing-xx-large);
  }

  .wrapper:last-child {
    margin-right: 0;
  }
}

@media only screen and (min-width: 1048px) {
  .wrapper {
    --number-row-width: 436px;
  }
}
